import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Heading, Stack, StackDivider, Text, VStack } from '@chakra-ui/react';
import moment from 'moment';
import DashboardData from '../components/DashboardData';
import PleaseWait from '../components/PleaseWait';
import AthleteProfile from '../components/AthleteProfile';
import PointsTable from '../components/PointsTable';
import ChallengeInfo from '../components/ChallengeInfo';
import useFetch from '../hooks/useFetch';
import AthleteStatistics from '../components/AthleteStatistics';

const Dashboard = (props) => {

  const navigate = useNavigate();
  const {
    data,
    loading,
  } = props;

  const isLoggedIn = data ? true : false && Boolean(data?.athleteId);

  const {
    data: leaderboardData,
    isLoading: leaderboardLoading,
  } = useFetch('v1/scores/challenge', [process.env.REACT_APP_CHALLENGE_ID], {});

  useEffect(() => {
    window.sessionStorage.setItem('loaderBoard', JSON.stringify(leaderboardData));
  });

  if (isLoggedIn === false) {
    navigate('/');
  }

  if (loading ? true : !!leaderboardLoading) {
    return (
      <PleaseWait />
    );
  }

  let cardData = (
    <DashboardData data={data} leaderboardLoading={leaderboardLoading} leaderboardData={leaderboardData} />);


  /* if (data.challengeStartDate > Date.now()) {
    cardData = (<Heading lineHeight={1.1} fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '6xl' }}>
      <Text as={'span'} bgGradient='linear(to-l, red.600,gray.600)' bgClip='text'>
        Challenge is yet to start! It starts on {' '}
      </Text>
      <Text as={'span'} bgGradient='linear(to-r, blue.600,red.600)' bgClip='text'>
        {moment(new Date(data.challengeStartDate)).utc(false).format('DD MMM YYYY')}
      </Text>
    </Heading>);
  } else if (data.challengeEndDate < Date.now()) {
    cardData = (<Heading lineHeight={1.1} fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '6xl' }}>
      <Text as={'span'} bgGradient='linear(to-l, red.600,gray.600)' bgClip='text'>
        Challenge has already ended! It ended on {' '}
      </Text>
      <Text as={'span'} bgGradient='linear(to-r, blue.600,red.600)' bgClip='text'>
        {moment(new Date(data.challengeEndDate)).utc(false).format('DD MMM YYYY')}
      </Text>
    </Heading>);
  } */

  return (
    <VStack spacing='1' width='100%' height='100%'>
      <Card width='100%' height='100%'>
        <CardHeader>
          <ChallengeInfo data={data} />
        </CardHeader>
      </Card>
      <Card width='100%' height='100%'>
        <CardHeader gap='1'>
          <Stack divider={<StackDivider />} spacing='6'>
            <AthleteProfile data={data} leaderboardData={leaderboardData} />
            <AthleteStatistics data={data} leaderboardData={leaderboardData} />
          </Stack>
        </CardHeader>
        <CardBody textAlign='center'>
          {cardData}
        </CardBody>
      </Card>
      <PointsTable />
    </VStack>
  );
};

export default Dashboard;
