import React from 'react';
import { Card, CardBody, CardHeader, Heading, Stat, StatLabel, StatNumber, VStack } from '@chakra-ui/react';
import PleaseWait from '../components/PleaseWait';
import useFetch from '../hooks/useFetch';

const ChallengeStats = () => {

  const {
    data: athleteCountData,
    isLoading: athleteCountLoading,
  } = useFetch('v1/athletes/count', [], {});

  if (athleteCountLoading) {
    return (
      <PleaseWait />
    );
  }

  return (
    <VStack spacing='1' width='100%' height='100%'>
      <Card width='100%' height='100%'>
        <CardHeader gap='1'>
          <Heading>Challenge Stats</Heading>
        </CardHeader>
        <CardBody textAlign='center'>
          <Stat borderWidth={'0.1em'} borderColor={'tomato'}>
            <StatLabel>Total Registrations</StatLabel>
            <StatNumber>{athleteCountData}</StatNumber>
          </Stat>
        </CardBody>
      </Card>
    </VStack>
  );
};

export default ChallengeStats;