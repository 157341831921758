import {
  Card,
  CardBody,
  CardHeader,
  Grid as GridLayout,
  GridItem,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import PleaseWait from './PleaseWait';
import { Prose } from '@nikolovlazar/chakra-ui-prose';
import { Base64 } from 'js-base64';
import { GroupingState, IntegratedGrouping } from '@devexpress/dx-react-grid';
import { Grid, Table as TableNew, TableGroupRow, TableHeaderRow } from '@devexpress/dx-react-grid-bootstrap4';
import useFetch from '../hooks/useFetch';

const PointsTable = () => {

  const convertMetricValue = (metricType, metricValue) => {
    switch (metricType) {
      case 'DISTANCE':
        return (metricValue / 1000) + ' kms';
      case 'TIME':
        return (metricValue / 60) + ' mins';
      case 'ELEVATION':
        return metricValue + ' mts';
    }
  };

  const toSnakeCase = str =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const {
    data: challengeData,
    isLoading: isChallengeDataLoading,
  } = useFetch('v1/challenges', [process.env.REACT_APP_CHALLENGE_ID], {});

  const [qualificationCriteriaColumns] = useState([
    { name: 'sport',
      title: 'Sport',
      getCellValue: row => {
        return row.sport.name;
      }
    },
    { name: 'type',
      title: 'Type',
      getCellValue: row => {
        return toSnakeCase(row.challengeType);
      }
    },
    { name: 'minimum',
      title: 'Minimum',
      getCellValue: row => {
        return convertMetricValue(row.challengeType, row.minMetric);
      }
    },
    { name: 'maximum',
      title: 'Maximum',
      getCellValue: row => {
        return convertMetricValue(row.challengeType, row.maxMetric);
      }
    },
  ]);

  const [cumulativePointsColumns] = useState([
    { name: 'sport',
      title: 'Sport',
      getCellValue: row => {
        return row.sport.name;
      }
    },
    { name: 'type',
      title: 'Type',
      getCellValue: row => {
        return toSnakeCase(row.challengeType);
      }
    },
    { name: 'unit',
      title: 'Unit',
      getCellValue: row => {
        return convertMetricValue(row.challengeType, row.baseScoreMetric);
      }
    },
    { name: 'points',
      title: 'Points',
      getCellValue: row => {
        return row.baseScore;
      }
    },
  ]);

  const [bonusPointsColumns] = useState([
    { name: 'sport',
      title: 'Sport',
      getCellValue: row => {
        return row.sport.name;
      }
    },
    { name: 'type',
      title: 'Type',
      getCellValue: row => {
        return toSnakeCase(row.challengeType);
      }
    },
    { name: 'target',
      title: 'Target',
      getCellValue: row => {
        return convertMetricValue(row.challengeType, row.targetScoreMetric);
      }
    },
    { name: 'points',
      title: 'Points',
      getCellValue: row => {
        return row.targetScore;
      }
    },
  ]);

  const [qualificationCriteriaRows, setQualificationCriteriaRows] = useState([]);
  const [cumulativePointsRows, setCumulativePointsRows] = useState([]);
  const [bonusPointsRows, setBonusPointsRows] = useState([]);

  useEffect(() => {
    if(challengeData) {
      setQualificationCriteriaRows(challengeData?.challengeDetailsForPoints);
      setCumulativePointsRows(challengeData?.challengeDetailsForPoints.filter((entry) => (entry.baseScore > 0)));
      setBonusPointsRows(challengeData?.challengeDetailsForPoints.filter((entry) => (entry.targetScore > 0)));
    }
  });

  if (isChallengeDataLoading) {
    return (
      <PleaseWait />
    );
  }

  const getDailyPointsTable = (entry) => {
    const dailyPointsColumn = [
      { name: 'unit',
        title: 'Unit'
      },
      { name: 'points',
        title: 'Points'
      }
    ];
    const dailyPointsRows = entry.bonusScores.map((be) => (
      {
        unit : convertMetricValue(entry.challengeType, be.metric),
        points: be.score
      }
    ));
    const columnExtensions = [
      { columnName: 'unit', align: 'center', width: 120},
      { columnName: 'points', align: 'center', width: 100},
    ];
    return (
      <Card textAlign='center' variant='outline'>
        <CardHeader>
          <Heading size='sm'>{entry.sport.name} - {toSnakeCase(entry.challengeType)}</Heading>
        </CardHeader>
        <CardBody>
          <Grid rows={dailyPointsRows}  columns={dailyPointsColumn}>
            <TableNew columnExtensions={columnExtensions}/>
            <TableHeaderRow />
          </Grid>
        </CardBody>
      </Card>
    );
  };



  return (
    <Tabs variant='unstyled' width='100%'>
      <TabList>
        <Tab _selected={{ color: 'white', bg: 'orange.500' }}>Qualification Criteria</Tab>
        <Tab _selected={{ color: 'white', bg: 'orange.500' }}>Cumulative Points</Tab>
        <Tab _selected={{ color: 'white', bg: 'orange.500' }}>Bonus Points</Tab>
        <Tab _selected={{ color: 'white', bg: 'orange.500' }}>Daily Points</Tab>
        <Tab _selected={{ color: 'white', bg: 'orange.500' }}>Important Information</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Grid rows={qualificationCriteriaRows}  columns={qualificationCriteriaColumns}>
            <GroupingState
              grouping={[{ columnName: 'sport' }]}
            />
            <IntegratedGrouping />
            <TableNew />
            <TableHeaderRow />
            <TableGroupRow />
          </Grid>
        </TabPanel>
        <TabPanel>
          <Grid rows={cumulativePointsRows}  columns={cumulativePointsColumns}>
            <GroupingState
              grouping={[{ columnName: 'sport' }]}
            />
            <IntegratedGrouping />
            <TableNew />
            <TableHeaderRow />
            <TableGroupRow />
          </Grid>
        </TabPanel>
        <TabPanel>
          <Grid rows={bonusPointsRows}  columns={bonusPointsColumns}>
            <GroupingState
              grouping={[{ columnName: 'sport' }]}
            />
            <IntegratedGrouping />
            <TableNew />
            <TableHeaderRow />
            <TableGroupRow />
          </Grid>
        </TabPanel>
        <TabPanel>
          <GridLayout width='100%' gap={2} templateColumns='repeat(3, 1fr)' templateRows='repeat(3, 1fr)'>
            {challengeData?.challengeDetailsForPoints.map((entry) => (
              <GridItem w='100%' h='100%' key={entry.sport.name +'-' +toSnakeCase(entry.challengeType)}>
                {getDailyPointsTable(entry)}
              </GridItem>
            ))}
          </GridLayout>
        </TabPanel>
        <TabPanel>
          <Card width='100%'>
            <CardBody>
              <Prose>
                <div style={{
                  height:"100vh",
                  width:" 100vw"}}
                     dangerouslySetInnerHTML={challengeData && { __html: Base64.decode(challengeData?.description) }} />
              </Prose>
            </CardBody>
          </Card>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default PointsTable;