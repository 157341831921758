import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Container,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import PleaseWait from './PleaseWait';
import React from 'react';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import useFetch from '../hooks/useFetch';
import { Navigate } from 'react-router-dom';

const RegistrationForm = (props) => {

  const {
    data: athleteData,
    isLoading: isAthleteDataLoading,
  } = useFetch('v1/athletes/meta', [process.env.REACT_APP_CHALLENGE_ID], {});

  const {
    data: challengeData,
    isLoading: isChallengeDataLoading,
  } = useFetch('v1/challenges', [process.env.REACT_APP_CHALLENGE_ID], {});

  const {
    data,
    loading,
    error,
    getAuth,
    logout,
    registrationData,
    onChangeRegistrationData
  } = props;

  const onEmailChange = (e) => {
    onChangeRegistrationData({ ...registrationData, email: e.target.value });
  };

  const onMobileChange = (e) => {
    onChangeRegistrationData({ ...registrationData, mobile: e.target.value });
  };

  if (loading || isChallengeDataLoading || isAthleteDataLoading) {
    return (<PleaseWait />);
  }

  if(error) {
    return  (
      <Navigate to='/' />
    );
  }

  return (
      <Box position={'relative'}>
        <Container
          as={SimpleGrid}
          maxW='100%'
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 10, lg: 32 }}
          py={{ base: 10, sm: 20, lg: 32 }}>
          <Stack spacing={{ base: 10, md: 20 }} width='100%'>
            <Image src={challengeData?.logoUrl}
                   alt={challengeData?.name} height={'l'} />
            <Stack direction={'row'} spacing={4} align={'center'}>
              <AvatarGroup>
                {athleteData?.map((avatar) => (
                  <Avatar
                    key={avatar.name}
                    name={avatar.name}
                    src={avatar.url}
                    position={'relative'}
                    zIndex={2}
                    _before={{
                      content: '""',
                      width: 'full',
                      height: 'full',
                      rounded: 'full',
                      transform: 'scale(1.125)',
                      bgGradient: 'linear(to-bl, red.400,pink.400)',
                      position: 'absolute',
                      zIndex: -1,
                      top: 0,
                      left: 0,
                    }}
                  />
                ))}
              </AvatarGroup>
              <Text fontFamily={'heading'} fontSize={{ base: '4xl', md: '6xl' }}>
                +
              </Text>
              <Avatar
                key={'you'}
                name={'YOU'}
                position={'relative'}
                getInitials={() => 'YOU'}
                zIndex={2}
                _before={{
                  content: '""',
                  width: 'full',
                  height: 'full',
                  rounded: 'full',
                  transform: 'scale(1.125)',
                  bgGradient: 'linear(to-bl, red.400,pink.400)',
                  position: 'absolute',
                  zIndex: -1,
                  top: 0,
                  left: 0,
                }}
              />
            </Stack>
          </Stack>
          <Stack
            bgGradient='linear(to-r, red.100,blue.100)'
            rounded={'xl'}
            p={{ base: 4, sm: 6, md: 8 }}
            spacing={{ base: 8 }}
            width='100%'>
            <Stack spacing={4} width='100%'>
              <Heading
                color={'gray.800'}
                lineHeight={1.1}
                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
                Join the{' '}
                <Text as={'span'} bgGradient='linear(to-r, blue.500,red.400)' bgClip='text'>
                  {challengeData?.name}!
                </Text>
              </Heading>
              <Text color={'gray.800'} fontSize={{ base: 'sm', sm: 'md' }}>
                Challenge yourself to be better everyday!
              </Text>
            </Stack>
            <Box as={'form'} mt={10}>
              <Stack spacing={4}>
                <InputGroup>
                  <InputLeftElement pointerEvents='none'>
                    <EmailIcon color='gray.600' />
                  </InputLeftElement>
                  <Input id='email' type='email' placeholder='Email address' onChange={onEmailChange} />
                </InputGroup>
                <InputGroup>
                  <InputLeftElement pointerEvents='none'>
                    <PhoneIcon color='gray.600' />
                  </InputLeftElement>
                  <Input id='mobile' type='tel' placeholder='Phone number' onChange={onMobileChange} />
                </InputGroup>
              </Stack>
              <Button
                fontFamily={'heading'}
                mt={8}
                w={'full'}
                bgGradient='linear(to-r, red.600,blue.600)'
                color={'white'}
                _hover={{
                  bgGradient: 'linear(to-l, red.600,blue.600)',
                  boxShadow: 'xl',
                }} onClick={(e) => {
                e.stopPropagation();
                getAuth();
              }}>
                Login
              </Button>
            </Box>
            form
          </Stack>
        </Container>
      </Box>
    );
};

export default RegistrationForm;