import { HStack, Stat, StatLabel, StatNumber, Text, useToken, VStack } from '@chakra-ui/react';
import React from 'react';
import PleaseWait from './PleaseWait';

const AthleteStatistics = (props) => {

  const [red100, blue200] = useToken(
    'colors',
    ['red.100', 'blue.200'],
  );

  const { data, leaderboardData } = props;

  if (leaderboardData) {
    return (
      <VStack width="100%" textAlign="center">
        <HStack width="100%" textAlign="center" boxShadow={`inset 0 4px 0 ${red100}, 0 0 8px ${blue200}`} gap="2">
          <Stat>
            <StatLabel>Distance (kms)</StatLabel>
            <StatNumber>{Math.round(leaderboardData[leaderboardData.findIndex((e) => e.athleteId === data.athleteId)].totalDistance)}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Elevation (mts)</StatLabel>
            <StatNumber>{Math.round(leaderboardData[leaderboardData.findIndex((e) => e.athleteId === data.athleteId)].totalElevation)}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Duration (hrs)</StatLabel>
            <StatNumber>{Math.round(leaderboardData[leaderboardData.findIndex((e) => e.athleteId === data.athleteId)].totalMovingTime)}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Points</StatLabel>
            <StatNumber>{isNaN(leaderboardData[leaderboardData.findIndex((e) => e.athleteId === data.athleteId)].totalScore) ? 0 : Math.round(leaderboardData[leaderboardData.findIndex((e) => e.athleteId === data.athleteId)].totalScore)}</StatNumber>
          </Stat>
        </HStack>
        <Text color={'red.800'} fontSize={{ base: 'sm', sm: 'md' }}>
          * Statistics shown here are overall statistics for the challenge period. Please refer to Qualification Criteria and Points allocation for each sport type at the bottom of the page.
        </Text>
      </VStack>
    );
  } else {
    return (<PleaseWait />);
  }
};

export default AthleteStatistics;
