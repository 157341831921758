import { CircularProgress } from '@chakra-ui/react';
import React from 'react';


const PleaseWait = () => {

  return (
    <CircularProgress isIndeterminate color='red.600' thickness='4px' size='xs'/>
  );
}

export default PleaseWait;