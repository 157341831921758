import { useEffect, useState } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';

const reShapeEndpoint = process.env.REACT_APP_RESHAPE_URL;

const useFetch = (endpoint, pathParams = [], queryParams = {}) => {

  const toast = useToast()
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const requestUrl = pathParams.length > 0 ? `${reShapeEndpoint}/${endpoint}/${pathParams.join('/')}` : `${reShapeEndpoint}/${endpoint}`;

  const options = {
    method: 'GET',
    url: `${requestUrl}`,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    params: { ...queryParams },
  };

  const fetchData = async () => {
    setIsLoading(true);
    setData(null);
    setError(null);
    try {
      const response = await axios.request(options);
      setData(response.data);
    } catch (error) {
      setError(error);
      toast({
        title: "Something went wrong!",
        status: 'error',
        isClosable: true,
      })
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [endpoint]);

  return { data, isLoading, error };
};

export default useFetch;