import React from 'react';
import { Box, Image } from '@chakra-ui/react';

export function PoweredByStrava() {

  return (
    <Box justifySelf='flex-end'>
      <Image src={process.env.PUBLIC_URL + '/images/strava.svg'}
             alt='Powered By Strava' height={'32px'} />
    </Box>
  );
}