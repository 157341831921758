import React, { useEffect, useState } from 'react';
import { Box, ChakraProvider, Grid, VStack } from '@chakra-ui/react';
import { OAuthPopup, useOAuth2 } from '@tasoskakour/react-use-oauth2';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { PoweredByStrava } from './components/PoweredByStrava';
import Home from './views/Home';
import Dashboard from './views/Dashboard';
import PleaseWait from './components/PleaseWait';
import ChallengeStats from './views/ChallengeStats';
import useFetch from './hooks/useFetch';


const App = (props) => {

  const { theme } = props;

  const { data, loading, error, getAuth, logout } = useOAuth2({
    authorizeUrl: 'https://www.strava.com/oauth/authorize',
    clientId: `${process.env.REACT_APP_CLIENT_ID}`,
    redirectUri: `${document.location.origin}/callback`,
    scope: 'read,activity:read_all',
    responseType: 'code',
    exchangeCodeForTokenServerURL: `${process.env.REACT_APP_RESHAPE_URL}/v3/registrations/${process.env.REACT_APP_CHALLENGE_ID}`,
    extraQueryParameters: { 'approval_prompt': 'force' },
    exchangeCodeForTokenMethod: 'POST',
    onSuccess: (payload) => console.log('Success', payload),
    onError: (error_) => console.log('Error', error_),
  });
  const [registrationData, setRegistrationData] = useState({ email: '', mobile: '' });

  const {
    data: challengeData,
    isLoading: isChallengeDataLoading,
  } = useFetch('v1/challenges', [process.env.REACT_APP_CHALLENGE_ID], {});

  useEffect(() => {
    if (challengeData) {
      window.sessionStorage.setItem('challengeMeta', JSON.stringify(challengeData));
    }
  }, []);

  if (loading || isChallengeDataLoading) {
    return (
      <PleaseWait />
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <Box display={{ lg: 'flex' }} p={4} maxW='2560px' maxH='1440px' w='100%' h='100%'>
        <Grid w='100%' p={3}>
          <VStack>
            <BrowserRouter>
              <Routes>
                <Route element={<OAuthPopup />} path='/callback' />
                <Route path='/'
                       element={<Home data={data} loading={loading} error={error} getAuth={getAuth} logout={logout}
                                      registrationData={registrationData}
                                      onChangeRegistrationData={setRegistrationData} />} />
                <Route path='/dashboard'
                       element={<Dashboard data={data} loading={loading} error={error} getAuth={getAuth} logout={logout}
                                           registrationData={registrationData}
                                           onChangeRegistrationData={setRegistrationData} />} />
                <Route path='/stats' element={<ChallengeStats />} />
              </Routes>
            </BrowserRouter>
            <PoweredByStrava />
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
};

export default App;
