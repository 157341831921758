import { Avatar, AvatarBadge, Box, Link, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
  TableFilterRow,
  SearchPanel,
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import PleaseWait from './PleaseWait';
import {
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
  IntegratedFiltering,
  SearchState,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import moment from 'moment';

const getHiddenColumnsFilteringExtensions = hiddenColumnNames => hiddenColumnNames
  .map(columnName => ({
    columnName,
    predicate: () => false,
  }));

const LeaderBoardTable = (props) => {

  const { data, leaderboardData } = props;

  const [columns] = useState([
    { name: 'rank', title: 'Rank' },
    {
      name: 'firstName',
      title: 'Name',
      getCellValue: row => {
        return row.firstName + ' ' + row.lastName;
      },
    },
    {
      name: 'totalScore',
      title: 'Points',
      getCellValue: row => {
        return isNaN(row.totalScore) ? 0 : Math.round(row.totalScore);
      },
    },
    {
      name: 'totalDistance',
      title: 'Distance (kms)',
      getCellValue: row => {
        return isNaN(row.totalDistance) ? 0 : Math.round(row.totalDistance);
      },
    },
    {
      name: 'totalElevation',
      title: 'Elevation (mts)',
      getCellValue: row => {
        return isNaN(row.totalElevation) ? 0 : Math.round(row.totalElevation);
      },
    },
    {
      name: 'totalMovingTime',
      title: 'Duration (hrs)',
      getCellValue: row => {
        return isNaN(row.totalMovingTime) ? 0 : Math.round(row.totalMovingTime);
      },
    },
    {
      name: 'lastUpdated',
      title: 'Last Updated',
      getCellValue: row => {
        return moment(new Date(row.lastUpdated)).utc(true).format('DD MMM YYYY  h:mm:ss a');
      },
    },
  ]);

  const [defaultHiddenColumnNames] = useState(['totalDistance', 'totalElevation', 'totalMovingTime', 'lastUpdated']);

  const [defaultFakeHiddenColumnNames] = useState(['totalScore', 'totalDistance', 'totalElevation', 'totalMovingTime', 'lastUpdated']);

  const [filteringColumnExtensions, setFilteringColumnExtensions] = useState(
    getHiddenColumnsFilteringExtensions(defaultFakeHiddenColumnNames),
  );

  const onHiddenColumnNamesChange = hiddenColumnNames => setFilteringColumnExtensions(
    getHiddenColumnsFilteringExtensions(defaultFakeHiddenColumnNames),
  );

  const [tableColumnExtensions] = useState([
    { columnName: 'rank', align: 'center', width: 100 },
    { columnName: 'firstName', align: 'center', width: 350 },
    { columnName: 'totalScore', align: 'center', width: 150 },
    { columnName: 'totalDistance', align: 'center', width: 170 },
    { columnName: 'totalElevation', align: 'center', width: 170 },
    { columnName: 'totalMovingTime', align: 'center', width: 170 },
    { columnName: 'lastUpdated', align: 'center', width: 220 },
  ]);

  const [rows] = useState(leaderboardData);

  const RankFormatter = ({ value }) => (
    <Avatar name={leaderboardData[leaderboardData.findIndex((e) => e.rank === value)].firstName}
            src={leaderboardData[leaderboardData.findIndex((e) => e.rank === value)].profilePicture} size='md'>
      <AvatarBadge borderColor='tomato' borderWidth='0.1em' bg='tomato' boxSize='1.25em' color={'white'}
                   textAlign={'center'} fontSize={'md'}>
        {leaderboardData[leaderboardData.findIndex((e) => e.rank === value)].rank}
      </AvatarBadge>
    </Avatar>
  );

  const NameFormatter = ({ value }) => {
    const currentAthleteName = data.firstName + ' ' + data.lastName;
    const athleteId = leaderboardData[leaderboardData.findIndex((e) => e.firstName + ' ' + e.lastName === value)].athleteId;
    if (value === currentAthleteName) {
      return (
        <Link as='b' color='tomato' href={'https://www.strava.com/athletes/' + athleteId} isExternal>
          {value}
        </Link>
      );
    } else {
      return (
        <Link href={'https://www.strava.com/athletes/' + athleteId} isExternal>
          {value}
        </Link>
      );
    }
  };

  const NameValueProvider = props => (
    <DataTypeProvider formatterComponent={NameFormatter}
                      {...props}
    />
  );


  const RankTypeProvider = props => (
    <DataTypeProvider formatterComponent={RankFormatter}
                      {...props}
    />
  );

  if(leaderboardData) {
    return (
      <Box width='100%'>
        <Grid rows={rows} columns={columns}>
          <RankTypeProvider for={['rank']} />
          <NameValueProvider for={['firstName']} />
          <SortingState defaultSorting={[{ columnName: 'rank', direction: 'asc' }]} />
          <IntegratedSorting />
          <PagingState defaultCurrentPage={0} pageSize={20} />
          <IntegratedPaging />
          <SearchState defaultValue='' />
          <IntegratedFiltering columnExtensions={filteringColumnExtensions} />
          <Table columnExtensions={tableColumnExtensions} />
          <TableHeaderRow showSortingControls />
          <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames}
                                 onHiddenColumnNamesChange={onHiddenColumnNamesChange} />
          <Toolbar />
          <SearchPanel />
          <ColumnChooser />
          <PagingPanel />
        </Grid>
      </Box>
    );
  } else {
    return (<PleaseWait />);
  }

};

export default LeaderBoardTable;