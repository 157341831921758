import { Box, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';


const ChallengeInfo = (props) => {

  const {data} = props;

  return (
    <Flex spacing='4' justify='left'>
      <Flex flex='1' gap='4' flexWrap='wrap'>
        <Image alt={data?.challengeName} src={data?.challengeLogoUrl} height='64px' />
        <Box>
          <Heading size='sm'>{data?.challengeName}</Heading>
          <VStack fontSize='xs'>
            <Text>Start Date: {moment(new Date(data?.challengeStartDate)).utc(false).format("DD MMM YYYY")}</Text>
            <Text>End Date: {moment(new Date(data?.challengeEndDate)).utc(false).format("DD MMM YYYY")}</Text>
          </VStack>
        </Box>
      </Flex>
    </Flex>
  );
}

export default ChallengeInfo;