import { Avatar, AvatarBadge, Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import PleaseWait from './PleaseWait';

const AthleteProfile = (props) => {

  const { data, leaderboardData } = props;

  if(leaderboardData) {
    if (Date.now() >= data.challengeStartDate && Date.now() <= data.challengeEndDate) {
      return (<Flex spacing='1' width='100%'>
          <Flex flex='1' gap='1' flexWrap='wrap'>
            <Avatar name={data.firstName} src={data.profilePicture} size='xl'>
              <AvatarBadge borderColor='tomato' borderWidth='0.1em' bg='tomato' boxSize='1.25em' color={'white'}>
                {leaderboardData[leaderboardData?.findIndex((e) => e.athleteId === data.athleteId)]?.rank}
              </AvatarBadge>
            </Avatar>
            <Box>
              <Heading size='sm'>{data.firstName} {data.lastName}</Heading>
              <Text>{data.city}, {data.state}</Text>
            </Box>
          </Flex>
        </Flex>
      );
    }

    return (
      <Flex spacing='1' width='100%'>
        <Flex flex='1' gap='1' flexWrap='wrap'>
          <Avatar name={data.firstName} src={data.profilePicture} size='xl'>
          </Avatar>
          <Box>
            <Heading size='sm'>{data.firstName} {data.lastName}</Heading>
            <Text>{data.city}, {data.state}</Text>
          </Box>
        </Flex>
      </Flex>
    );
  } else {
    return (<PleaseWait />);
  }
};

export default AthleteProfile;