import React from 'react';
import PleaseWait from './PleaseWait';
import { StackDivider, VStack } from '@chakra-ui/react';
import AthleteStatistics from './AthleteStatistics';
import LeaderBoardTable from './LeaderBoardTable';


const DashboardData = (props) => {

  const { data, leaderboardLoading, leaderboardData } = props;

  if (leaderboardLoading) {
    return (<PleaseWait />);
  }

  return (
    <VStack divider={<StackDivider borderColor='gray.200' />} spacing={1}>
      <LeaderBoardTable data={data} leaderboardData={leaderboardData} />
    </VStack>
  );
};

export default DashboardData;